<!-- [ngClass]="{'fixedHeight': manualSelectionCss}" -->
<table
  mat-table
  class="table-responsive responsiveContent"
  multiTemplateDataRows
  [dataSource]="dataSource"
  PrismaTableFlexColumn
  [PrismaTableMinWidth]="{colSpan: totalColSpan, minCellWidth: _minCellWidth }"
  [style.width]="_fixedTableWidth"
  style="overflow-x: hidden !important"
>
  <!-- COLUMNA DESPLEGABLE DE SUB ROWS - la que tiene el icono de desplegable  -->
  <ng-container *ngIf="attrSubRows">
    <ng-container matColumnDef="collapseDetail">
      <mat-header-cell
        *matHeaderCellDef
        class="pl-0"
        PrismaTableFlexColumn
        [PrismaTableMinWidth]="{colSpan: _colSpanDropDown, minCellWidth: _minCellWidth}"
        [PrismaTableFlex]="_colSpanDropDown"
      ></mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        class="pl-0"
        PrismaTableFlexColumn
        [PrismaTableMinWidth]="{colSpan: _colSpanDropDown, minCellWidth: _minCellWidth}"
        [PrismaTableFlex]="_colSpanDropDown"
        fxLayoutAlign="center center"
      >
        <ng-container *ngIf="stateOfCollapseRow(element)">
          <span
            class="iconRowClosed clickeable"
            *ngIf="!element.expanded && stateOfCollapseRow(element)"
            (click)="toggleExtand(element); $event.stopPropagation()"
          >
            <mat-icon>keyboard_arrow_right</mat-icon>
          </span>
          <span
            class="iconRowExpanded clickeable"
            *ngIf="element.expanded && stateOfCollapseRow(element)"
            (click)="toggleExtand(element); $event.stopPropagation()"
          >
            <mat-icon>keyboard_arrow_down</mat-icon>
          </span>
        </ng-container>
      </mat-cell>
    </ng-container>
  </ng-container>

  <!-- CUSTON COLUMNS FOR ACTIONS/CUSTOMS PROPOSES -->
  <ng-content></ng-content>
  <!-- AGRUPADO DE COLUMNAS -->

  <!-- DINAMIC COLUMNS -->
  <ng-container *ngFor="let _group of headerValues">
    <ng-container *ngFor="let _column of _group.type; let i = index;">
      <ng-container matColumnDef="{{_column.column}}">
        <mat-cell
          PrismaTableFlexColumn
          [PrismaTableMinWidth]="{colSpan: getColSpan(_column), minCellWidth: _minCellWidth }"
          [PrismaTableFlex]="getColSpan(_column)"
          class="{{_column.class}}"
          [ngClass]="{'_col_un minVal align-center' : groupedColumns}"
          *matCellDef="let _element"
          [fxLayoutAlign]="_column.fxLayoutAlign ? _column.fxLayoutAlign : 'start center'"
          [matTooltip]="_column.cellToolTip ? _column.cellToolTip : _column.customCellTitleToolTip ? _column.customCellTitleToolTip(_element) : null"
        >
          <prisma-table-column-content
            [element]="_element"
            [configColumn]="_column"
            [rowWithHover]="rowWithHover"
          ></prisma-table-column-content>
        </mat-cell>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- SUB ROW CON DATOS A DESPLEGAR -->
  <ng-container matColumnDef="expandedDetail">
    <div *matCellDef="let element">
      <prisma-table-sub-row
        *ngIf="element.expanded"
        [dataSource]="element[attrSubRows]"
        [columnsConfig]="columnsConfig"
        [attrSubRows]="attrSubRows"
        [colSpanDropDown]="_colSpanDropDownBase + ( (levelTree + 1) * 0.15 )"
        [levelTree]="levelTree + 1"
        [clickeable]="_clickeable"
        [checkBoxData]="checkBoxData"
      >
        <!-- desarrollo de funcionalidad de multiselect en tablas n-tree suspendida  -->
        <!--         <ng-container matColumnDef="select">
          <mat-header-cell
            *matHeaderCellDef
            class="checkbox-width"
            PrismaTableFlexColumn
            [PrismaTableSize]="{ column: columnsType, columnHeader: 'select'}"
          ></mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            class="checkbox-width"
            PrismaTableFlexColumn
            [PrismaTableSize]="{ column: columnsType, columnHeader: 'select'}"
          >
            <column-check-box-cell-v2
              [checkBoxData]="checkBoxData"
              (checkBoxDataOut)="_updateCheckBoxData($event)"
              [item]="element"
            ></column-check-box-cell-v2>
          </mat-cell>
        </ng-container> -->
      </prisma-table-sub-row>
    </div>
  </ng-container>

  <!-- ROW CONFIG PRINCIPAL -->
  <mat-row
    [ngClass]="{'mat-row-highlight': _clickeable, 'mat-row-highlight-no-pointer': !_clickeable, 'table-without-checkbox':_clickeable  }"
    *matRowDef="let row; columns: displayedColumns; let irow = dataIndex;"
    [attr.data-test-id]="'prisma_table_index_subrow_'+irow"
    PrismaTableFlexColumn
    [PrismaTableMinWidth]="{colSpan: totalColSpan, minCellWidth: _minCellWidth }"
    [style.width]="_fixedTableWidth"
    (mouseenter)="onMouseEnter(row)"
    (mouseleave)="onMouseLeave(row)"
    (click)="openRow(row)"
  ></mat-row>

  <!-- ROW CONFIG PARA DESPLEGABLE -->
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="collaspsed-sub-row"></tr>
</table>
