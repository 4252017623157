import { Environment } from './environment.model';
import packageJson from '../../package.json';
import build_version from '../../build_version.json';

//  Si hay que modificar una propiedad: editar el cambio en environment.dev
//  Para agregar endPoints acá está bien.

export const defaultEnvironment: Environment = {
  _versionFE: build_version.version,
  _versionFEpck: packageJson.version,
  _buildDate: '',
  forceOidcSecurityServiceRedirect: null,
  forceOidcSecurityServiceRedirectBy: null,
  production: true,
  inMemoryData: false,
  isVerbose: false,
  isVerboseTaps: false,
  isVerboseDeSubscribe: false,
  useDummyData: false,
  disableErrorModals: false,
  timeOutAppShowProgressBarAction: 10000,
  timeOutAppIsInSave: 2 * 60 * 1000,
  oidc_configuration: 'assets/auth.clientConfiguration.json',
  firebase: {
    apiKey: 'AIzaSyBq4KkB74tuz0LFyojk8mMfVtrA2NHIjUQ',
    authDomain: 'prisma-firebase.firebaseapp.com',
    databaseURL: 'https://prisma-firebase.firebaseio.com',
    projectId: 'prisma-firebase',
    storageBucket: 'prisma-firebase.appspot.com',
    messagingSenderId: '467838294373',
    appId: '1:467838294373:web:72d41ff3fb24b5e1',
  },
  local: true,
  localStorageKeyVerPrefix: '_v3_',
  disabledEnterItemTransition: true,

  useLocalStorageToCacheEndPoints: true,
  maxTimeuseLocalStorageToCacheEndPoints: 3 /* horas */ * 60 * 60 * 1000,
  useLocalStorageToCacheBrandCustomization: false,
  maxTimeToLocalStorageToCacheBrandCustomization: 2 /* horas */ * 60 * 60 * 1000,
  useBrandCustomizationV2: true,

  priceDecisionExecuteMassiveActionSuccessAction_disableSnack: true,
  priceDecisionExecuteMassiveAction_MaxBulkItemsSelectedLimit: 0 ?? 100 * 1000, //Se elimina con DES-3433

  useUXV2: true, //DES-1416
  saveGlobalSettingsInLocalStorage: true, //DES-1416

  enabledLangUnits: true,
  langUnits: {
    //DES-1640
    $: '$',
    'cm.': 'cm.',
    cm3: 'cm³',
    m3: 'm³',
  },

  enableTthirdPartyPlatforms: true, //DES-5109

  atlassianJiraUrl: 'https://prismaretail.atlassian.net/browse/',

  prismaDynamicEnv: {
    brandCustomizationV2: 'api/config/get-brand-customization-v2',
  },

  notifications: {
    useLocalStorage: true,
    toastDuration: 5 * 1000 /* 0 infinito */,
    forceDatesTogmt0: false,
    maxServerNoReadNotif: 100,
    maxServerReadNotif: 100,
    delayToGetFirstsNotifications: 10 * 1000,
  },

  statusConnectionConfig: {
    checkConnection: true,
    checkConnectionOnLocal: false,
    checkInterval: 3 * 60 * 1000,
    goodConnectionThresholdKbps: 100,
    baseUrlAsset: false ? '{{BASE_URL}}/config/get-dummy-asset' : 'assets/null.jpg',
    snackOnFail: true,
    snackTime: 60 * 60 * 1000,
    avoidErrorsOnPoorConnection: true,
  },

  //DES-391 //DES=1695
  assorment: {
    is_config_in_readonly: false,
  },

  frontendUrl: '/',
  identityUrl: '/',
  pricesUrl: '/',

  apiBaseUrl_prices: '/api/',
  apiBaseUrl_reports: '/api/',
  apiBaseUrl_assortment: '/api/',
  apiBaseUrl_assortmentEngine: '/api/',
  apiBaseUrl_mysteryshopper: '/api/',
  apiBaseUrl_identity: '/api/',
  apiBaseUrl_promotionV2: '/api/promotion/',
  apiBaseUrl_notification: '/api/notification/',
  apiBaseUrl_spaces: '/api/',
  apiBaseUrl_supply: '/api/',
  apiBaseUrl_salesandstock: '/api/salesandstock/',
  apiBaseUrl_blobStorage: 'https://iantechdevstorage.blob.core.windows.net/',

  itemMasterDefaultUnitsOptions: [
    { name: 'Unidad', code: 'U' },
    { name: 'Bulto', code: 'B' },
  ],

  leafletConfigs: {
    //Usado por getConfigLeafletConfigMap

    //openstreetmap
    OSM: { url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', options: { maxZoom: 19, attribution: '...' } },

    //mapbox
    MAP_BOX: {
      url: 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      options: {
        attribution: '...',
        maxZoom: 18,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        accessToken: 'pk.eyJ1Ijoia2FyYWNhcyIsImEiOiJjbDBzaWJ2NGEwMjFsM2ttZWJjOWdvYjJsIn0.0pnsw6KlCqxCHoQDbhJ7wQ',
      },
    },
  },

  imageFile: 'imageFile',

  featureFlags: {
    'DES-4316__CONFIG_DE_IMPORTADORES_DESDE_BE': true,
    'DES-3126__CHECK_USER_STORE': true, // WIP
    'DES-4362__STORE_REPORT_PDV': true, //DES-4382/DES-4362 > Prendido por default (done)
    'DES-3758__ASYNC-IMPORT-VENDOR-COSTS': true, // WIP
    //
    'CAT-182__LISTADO_EDICION_PLANOGRAMAS': false, // CAT-182
    'CAT-214__ESPACIOS_BLOQUEADOS': false, // WIP
    'DES-2998__PLANOGRAMAS_ITEM_SCOPE': false, // WIP
    'DES-3172__CONFIG_DATE_FORMAT_ON_SETTING_PANEL': false, //WIP
    'DES-3434__SUGERENCIAS_CUALQUIER_NIVEL_DE_CATEGORIA': false, // WIP
    'DES-3662__VIEW_COMPETITION_PRICE': false, // WIP
    'DES-3XXX__AUTOCOMPLETE_ITEM': false, // WIP
    'DES-3898__NEW-DECISION-SUMMARY': false, // WIP
    'DES-4510__SPC-FEATURES-PARA-POC-SODIMAC': false,
    'DES-4971__LISTADO-DE-MATERIAL-POP': false,
    'GOD-188__IMPLEMENTATION-MODE': false,
    'PRO-256__PROMOTION-COMBOS-FUSION': true,
    'CAT-195__SHOW_ONLY_SUGGESTIONS_FILTER': false,
    'PREC-287__TAB_DE_EQUIVALENCIAS_EN_IM': false,
    'PREC-221__REVISION_DEENDPOINT_VIEJO_DE_ATTR': false,
    'POC-XXX__ADD_OPTIMAL_PRICE_ITEMS_TO_LIST': true,
  },

  test: {
    checkbox: {
      api: {
        getAttributes: 'test/checkbox/getAttributes',
        getAttributesList: 'test/checkbox/getAttributesList',
        getList: 'test/checkbox/getList',
        getListNotPaged: 'test/checkbox/getListNotPaged',
      },
    },
    prismaTable: {
      api: {
        getList: 'test/prismaTable/getList',
      },
    },
  },
  promotionV2: {
    periods: {
      api: {
        resources: {
          feature: 'period',
          calendar: 'period/calendar',
          list: 'period/list',
          getById: 'period/get/',
          clone: 'period/clone-periods',
          save: 'period/save',
          codeExists: 'period/code-exists',
          getPromotionTypeList: 'period/promotion-types',
          getPeriodsReport: 'report/period-save-error-report',
        },
      },
    },
    marketplace: {
      api: {
        resources: {
          list: 'spaces/marketplace/list',
          getStoresBySpace: 'spaces/marketplace/store-list',
          editMarketplace: 'spaces/marketplace/rent',
          //
          feature: 'spaces/marketplace',
          calendar: 'spaces/marketplace/calendar',
          getById: 'spaces/marketplace/get/',
          save: 'spaces/marketplace/save',
          codeExists: 'spaces/marketplace/code_exists',
          getPromotionTypeList: 'spaces/marketplace/promotion-types',
        },
      },
    },
    campaign: {
      api: {
        resources: {
          feature: 'campaign',
          list: 'campaign/list',
          getById: 'campaign/get/',
          save: 'campaign/save',
          codeExists: 'campaign/code_exists',
        },
      },
    },
    action: {
      api: {
        resources: {
          list: 'action/list',
          getActionById: 'action/get',
          codeExists: 'action/code_exists',
          codeExternalExists: 'action/codeExternal_exists',
          saveAction: 'action/save',
          publishAction: 'action/publish',
          salesChannelCodes: 'sales-channel/list',
          loyaltyProgramCodes: 'loyalty-program/list',
          bankList: 'bank/list',
          PaymentMethods: 'payment-method/list',
          listItemClone: 'action/clone',
          listItemCancel: 'action/cancel',
          massivePublish: 'action/massive_publish',
          delete: 'action/massive-cancel', //DES-4890
          checkCoverage: 'action/check-coverage',
          nonLaborDays: 'action/non_labor_days',
          fixedPriceDiscounts: 'action/get-fixed-price-discounts',
          //
          downloadReport: 'report/multiple-promotion-report',
          generateProductListReport: 'report/production-list',
          generateLogisticsReport: 'report/production-list-logistics',
          generatePromotionReport: 'report/promotion-report-async',
          actionPromotionReport: 'report/promotion-report',
          vendorList: 'report/print-shops',
          actionValidation: 'validation/list',
          setValidationStatus: 'validation/set-validation-status',
          actionExceptionalValueList: 'action/action-exceptions-list', //DES-4645
          actionExceptionalValueListMixed: 'action/action-exceptions-mixed-list', //PRO-280
          validationReport: 'report/validation-report', //DES-4919
          revalidate: 'action/re-validate',
          savePromotionalSpaces: 'action/save-space',
          availablePromotionalSpacesList: 'report/get-spaces-availability', //PRO-333
          generateAvailablePromotionalSpacesReport: 'report/production-list', //PRO-333 //[TODO de BE]
        },
      },
    },
  },
  itemmasterv2: {
    api: {
      resources: {
        delete: 'item/delete',
        massivePublish: 'item/publishItems',
        undelete: 'item/undelete',
        exist: 'item/exists',
        getList: 'item/list',
        codeExists: 'item/item_code_exists',
        getItem: 'item/',
        saveItem: 'item/save',
        publishItem: 'item/publish',
        getItemCodeTypes: 'item/item_code_types',
        clone: 'item/clone',
        get_item_costs_taxes: 'item/get_item_costs_taxes',
        lookup: 'item/lookup',
        assortment_category_setup: 'item/get_assortment_tag',
        notifyUserPermission: 'item/notify-user-with-permission',
        validateUomsToClonePrices: 'item/validate_uoms_to_clone_prices',
        /**/
        getVendors: 'vendors/dropdown-vendors-item-master',
        searchItemsParent: 'vendors/items', // deberia ser un endpoint de item master - reutilizado de abm vendors
        getOptionsSelected: 'vendors/items-selected', // obtener nombre codigo id de items
        getTaxes: 'taxes/get-taxes',
        getCosts: 'costs/get-costs',
        /**/
        getGenericItemList: 'item/list-generic',
        getGenericItemListV2: 'item/list-generic-specified-filter',
        assortmentStatusFutureReport: 'assortment/assortment/assortment-status-future-report',
        itemScopeList: 'item/get-itemids-from-scope',
        toStandardItem: 'item/update-custom-item-to-standard-item',
      },
    },
  },
  assortment: {
    setup: {
      strategy: {
        api: {
          resources: {
            feature: 'assortment/strategy',
            list: 'assortment/strategy/list',
          },
        },
      },
      clustering: {
        api: {
          resources: {
            feature: 'assortment/clustering',
            list: 'assortment/clustering/list',
            getClusteringByStore: 'assortment/clustering/get-by-store-id',
          },
        },
      },
      clusterAssignment: {
        api: {
          resources: {
            list: 'assortment/cluster/stores',
            feature: 'assortment/cluster',
          },
        },
      },
      categorySetup: {
        api: {
          resources: {
            list: 'assortment/category-setup/list',
            clusterizationList: 'assortment/category-setup/clusterings',
            clusterList: 'assortment/category-setup/cluster',
            rolesList: 'assortment/category-setup/roles',
            strategiesList: 'assortment/category-setup/strategies',
            saveRSA: 'assortment/category-setup/save-rsa',
            updateClusterizationByCategory: 'assortment/category-setup/update-clusterization-for-category',
            updateStrategyForCategory: 'assortment/category-setup/update-strategy-for-category',
            updateRolForCategoryCluster: 'assortment/category-setup/update-rol-for-category-cluster',
          },
        },
      },
      segmentAssignment: {
        api: {
          resources: {
            list: 'assortment/segment-assignment/list',
            tagList: 'assortment/segment-assignment/tags',
            saveSegmentAssignment: 'assortment/segment-assignment/save-segment-assignment',
            saveSegmentAssignmentTag: 'assortment/segment-assignment/save-segment-assignment',
          },
        },
      },
      categorysTree: {
        api: {
          resources: {
            list: 'assortment/category-tree/list',
            save: 'assortment/category-tree/save',
          },
        },
      },
      strategies: {
        api: {
          resources: {
            list: 'assortment/strategy/list',
            getCombos: 'assortment/strategy/get-combos',
            getById: 'assortment/strategy',
            save: 'assortment/strategy',
          },
        },
      },
      roles: {
        api: {
          resources: {
            list: 'assortment/role/list',
            bulkDelete: 'assortment/role/bulk-delete',
            getById: 'assortment/role/get-by-id',
            save: 'assortment/role/save',
            dataCombosPrefix: 'assortment/role/get-combo',
            //
            getRolesForCategory: 'assortment/role/get-roles-for-category',
            updateRolForCategoryCluster: 'assortment/category-setup/update-rol-for-category-cluster',
            saveRol: 'assortment/role/save',
            generateSuggestions: 'assortment/suggestion/generate-suggestions',
            rolNameExists: 'assortment/role/rol-name-exists', //?idRole=&roleName=,
            generateSuggestionsItemMarket: 'assortment/suggestion/generate-market-suggestions',
          },
        },
        formCombosNeeded: [],
        formCombosNeededTimeCache: '5m' /* ms.js format */,
      },
      marketInformation: {
        api: {
          resources: {
            list: 'assortment/market-data/list-market-items',
            massiveAssignItems: 'assortment/market-data/market-item-category',
            getCategories: 'assortment/market-data/list-market-categories',
          },
        },
      },
    },
    decisions: {
      assortmentAsignation: {
        api: {
          resources: {
            list: 'assortment/assortment-assignation/list',
            categorys: 'assortment/assortment-assignation/categories',
            clusters: 'assortment/assortment-assignation/clusters',
            toggleAssignation: 'assortment/assortment-assignation/toggle-assignation',
            approves: 'assortment/assortment-assignation/approves',
            Approved: 'assortment/assortment-assignation/mark-as-approved',
            toERP: 'assortment/assortment-assignation/inform-to-erp',
          },
        },
      },
      assortmentAssignationItem: {
        api: {
          resources: {
            listClusterPareto: 'assortment-engine/pareto/get-pareto',
            listCategoryPareto: 'assortment-engine/pareto/get-pareto-for-category',
            listCategoryParetoKpis: 'assortment-engine/pareto/get-pareto-for-category-kpis-total',
            getCategoryName: 'categories',
            getClusterName: 'assortment/cluster/get-by-id',
            getClusterKpis: 'assortment-engine/pareto/get-cluster-kpis-by-id',

            list: 'assortment/assortment-assignation-item/list',
            toggleItemSkuAssignation: 'assortment/assortment-assignation-item/toggle-item-sku-assignation',
            listCategory: 'assortment/assortmentAsignationItem/list-category',
            changeStatusBySuggestion: 'assortment/assortment-assignation-item/change-status-by-suggestion',
            changeassortment_TotalGeneral: 'assortment/assortment-assignation-item/toggle-item-assortment-behavior-for-category',
            changeStatusMasiveBySuggestion: 'assortment-engine/pareto/change-status-masive-by-suggestion',

            addToCluster: 'assortment/cluster/add-to-cluster',
            removeFromCluster: 'assortment/cluster/remove-from-cluster',
            assortmentReportExceptions: 'assortment/store-assortment/report',

            // acciones masivas
            addAllToCluster: 'assortment/cluster/add-to-all-clusters',
            removeAllFromCluster: 'assortment/cluster/remove-from-all-clusters',
            generateSuggestion: 'assortment/suggestion/generate-delist',

            //
            listCategoryParetoWithFilters: 'assortment-engine/pareto/get-pareto-for-category',
          },
        },
      },
      informToErp: {
        api: {
          resources: {
            informToErp: 'assortment/assortment/Inform-to-erp',
            previewErp: 'assortment/assortment/Inform-to-erp-without-update',
            getList: 'assortment/assortment/get-last-inform-to-erp-reports',
            downloadInfoErp: 'assortment/assortment/deprecatd__',
            generateReportAsync: 'assortment/assortment/publish-assortment-decisions',
            previewAsync: 'assortment/assortment/preview-assortment-decisions',
          },
        },
      },
    },
    admin: {
      importer: {
        marketInformation: 'assortment/importer/market-data',
        urlTemplate: 'assortment/importer/template',
        storeAssortment: {
          api: {
            resources: {
              feature: 'storeAssortment',
              importer: 'assortment/importer/process-files/StoreAssortment',
              templateUrl: 'assortment/importer/template',
              assortmentStatusGeneral: 'assortment/assortment/assortment-status',
              assortmentStatusFuture: 'assortment/assortment/assortment-status-future',
            },
          },
        },
      },
    },
  },
  prices: {
    setup: {
      brands: {
        api: {
          resources: {
            feature: 'brands',
            list: 'brands/list',
            listPractica: 'practica/list',
          },
        },
      },
      competitors: {
        api: {
          resources: {
            feature: 'competitors',
            list: 'competitors/list',
            listCompetitorsScope: 'competitors/get-by-filter',
            listCompetitorsTradeArea: 'competitors/getCompetitorsByIds',
            listForExport: 'competitors/list-for-export',
            shatedAttributes: 'competitors/attributes/form',
            shatedAttributesFilter: 'competitors/attributes/filter',
            getById: 'competitors',
            importerCompetitorPrices: 'competitors/importer/competitor-prices',
            importerCompetitorPrices314: 'competitors/importer/competitor-prices-314',
            getCompetitorsTradeArea: 'competitors/getCompetitorsByTradeAreaFromStores',
          },
        },
      },
      priceRules: {
        api: {
          resources: {
            feature: 'price-rules',
            saveCompetence: 'price-rules/save-competence',
            saveMargin: 'price-rules/save-margin',
            getCompetence: 'price-rules/get-competence',
            getMargin: 'price-rules/get-margin',
            list: 'price-rules/list',
            filterAttributes: 'price-rules/attributes/filter',
            getById: 'price-rules',
            competenceRuleAttributes: 'price-rules/competence/attributes/form',
            marginRuleAttributes: 'price-rules/margin/attributes/form',
            execSuggestion: 'price-rules/generate-suggestions',
            skusInScope: 'price-rules/skus',
            storesInScope: 'price-rules/stores',
            existRulesType: 'price-rules/rules-type-exist',
            filterManufacturer: 'tags/manufacturer',
            storeList: 'rule-parent-store/get-stores-by-attributes',
            productList: 'rule-parent-store/get-items-by-attributes',
            getCompetitiveRuleAttributes: 'price-rules/get-competence-list',
          },
        },
      },
      storeParent: {
        api: {
          resources: {
            feature: 'rule-parent-store',
            stores: 'rule-parent-store/stores',
            childs: 'rule-parent-store/stores/childs',
            list: 'rule-parent-store/list',
            attributes: 'rule-parent-store/attributes/form',
            categories: 'rule-parent-store/categories',
            subcategories: 'rule-parent-store/subcategories',
            storeTags: 'tags/attributes-by-tag-target',
            attributesByTagTarget: 'tags/attributes-by-tag-target',
            searchStoresParent: 'rule-parent-store/stores-parents',
            productList: 'rule-parent-store/get-items-by-attributes',
            storeList: 'rule-parent-store/get-stores-by-attributes',
            getOptionsSelected: 'rule-parent-store/stores-parent-selected',
          },
        },
      },
      itemParent: {
        api: {
          resources: {
            feature: 'rule-parent-item',
            items: 'rule-parent-item/items',
            childs: 'rule-parent-item/items/childs',
            list: 'rule-parent-item/list',
            attributes: 'rule-parent-item/attributes/form',
            itemTags: 'tags/attributes-by-tag-target',
            attributesByTagTarget: 'tags/attributes-by-tag-target',
            searchItemsParent: 'rule-parent-item/items-parents',
            getOptionsSelected: 'rule-parent-item/items-parents-selected',
            categories: 'rule-parent-item/categories',
            subcategories: 'rule-parent-item/subcategories',
            productList: 'rule-parent-item/get-items-by-attributes',
            storeList: 'rule-parent-item/get-stores-by-attributes',
            getCategoriesBySubcategories: 'categories/get-parents',
            /*[wip]new 2k1bt2*/
            categoryTree: 'assortment/category-tree/list',
          },
        },
      },
      rounding: {
        api: {
          resources: {
            feature: 'rounding',
            list: 'rounding/list',
            filterAttributes: 'rounding/attributes/filter',
            formAttributes: 'rounding/attributes/form',
            filterManufacturer: 'tags/manufacturer',
          },
        },
      },
      sensitivities: {
        api: {
          resources: {
            feature: 'sensitivity',
            list: 'sensitivity/list',
            listForExport: 'sensitivity/list-for-export',
            attributes: '',
            getById: 'sensitivity',
          },
        },
      },
      guidelinesAndStrategies: {
        api: {
          resources: {
            feature: 'categories',
            list: 'categories/tree-list',
            form: 'categories/guideline-strategy',
          },
        },
      },
    },
    decisions: {
      decisionSummary: {
        api: {
          resources: {
            summary: 'decisions-summary/list',
            markAsReviewed: 'decisions-summary/mark-as-review',
            filterAttributes: 'decisions-summary/attributes/filter',
            getKpisToSend: 'decisions-summary/get-kpis-to-review',
            getKpisDecisionsSummary: 'decisions-summary/get-kpis-decisions-summary',
            getKpisToAprove: 'decisions-summary/get-kpis-to-approve',
            markAsApproved: 'decisions-summary/mark-as-approved',
            refresh: 'decisions-summary/refresh',
          },
        },
      },
      informToErp: {
        api: {
          resources: {
            generateReport: 'decisions-summary/generate-report',
            generateReportAsync: 'decisions-summary/publish-price-decisions',
            preview: 'decisions-summary/generate-report-without-update',
            previewAsync: 'decisions-summary/preview-price-decisions',
            downloadInfo: 'report/ypf-fuels-all-information',
            getList: 'decisions-summary/get-last-reports',
          },
        },
      },
      priceDecisions: {
        api: {
          resources: {
            feature: 'decisions',
            list: 'decisions/list',
            listCount: 'decisions/list/count',
            filterAttributes: 'decisions/attributes/filter',
            getById: 'decisions', // todavia no se usa
            getListViewKpis: 'decisions/kpis',
            getSuggestionTab: 'decisions/suggestions',
            getCompetitorMapTab: 'decisions/price-map',
            getCompetitorMapTabKpis: 'decisions/tab/price-map/kpis',
            getCompetitorSteps: 'decisions/tab/competitor-step-by-step',
            getChildsStoreTab: 'decisions/childsStore',
            savePrice: 'decisions/save-price',
            action: 'decisions/action',
            getCostsAndMarginsTab: 'decisions/costs-and-taxes',
            individualAction: 'decisions/single-action',
            getKpisTab: 'decisions/tab/kpis',
            getAuditTab: 'decisions/tab/audit',
            actionStrategy: 'decisions/action-strategy',
            setEffectiveDates: 'decisions/set-effective-dates',
            attributesByTagTarget: 'tags/attributes-by-tag-target',
            getRecipeTab: 'Decisions/tab/recipe',
            getComboProductTab: 'Decisions/tab/combos/product',
            getZonesTab: 'decisions/tab/header',
            getInfoHeaderTab: 'decisions/tab/header',
            getZonesList: 'decisions/tab/zones',
            suggestion: 'decisions/tab/suggestions',
            stepBySteps: 'decisions/tab/step-by-step',
            suggestionAnalysis: 'decisions/tab/suggestion-analysis',
            getComboTab: 'decisions/tab/combos',
            getAlertAndCap: 'decisions/tab/warnings-caps',
          },
        },
      },
    },
    admin: {
      taxes: {
        co2: {
          api: {
            resources: {
              feature: 'taxes/co2',
            },
          },
        },
        icl: {
          api: {
            resources: {
              feature: 'taxes/icl',
            },
          },
        },
        iibb: {
          api: {
            resources: {
              feature: 'taxes/iibb',
            },
          },
        },
      },
      importParity: {
        api: {
          resources: {
            feature: 'costs/import-parity',
            importer: 'costs/import-parity/monthly',
          },
        },
      },
      transportAndComissions: {
        api: {
          resources: {
            feature: 'cost/transport-and-comissions',
            importer: 'costs/transport-and-comissions',
          },
        },
      },
      costAndTaxes: {
        api: {
          resources: {
            feature: 'costs/costAndTaxes',
            importer: 'costs/costAndTaxes',
          },
        },
      },
      vendorCosts: {
        api: {
          resources: {
            feature: 'cost/vendor-costs',
            importer: 'costs/vendor-costs',
          },
        },
      },
      vendors: {
        api: {
          resources: {
            feature: 'import/vendor',
            getExcel: 'import/vendors/downloadExcel',
          },
        },
      },
      storeTaxes: {
        api: {
          resources: {
            feature: 'taxes/store-taxes',
            importer: 'taxes/store-taxes',
          },
        },
      },
      newPrices: {
        api: {
          resources: {
            feature: 'decisions/new-prices',
            importer: 'decisions/new-prices',
          },
        },
      },
      competitorPrices: {
        api: {
          resources: {
            feature: 'competitors',
            importer: 'competitors/importer/prices-of-competitor',
          },
        },
      },
      importer: {
        api: {
          resources: {
            urlTemplate: 'admin/importer/template',
          },
        },
      },
    },
    reports: {
      ypfReports: {
        api: {
          resources: {
            competitivePosition: 'report/competitive-position',
            competitorPrices: 'report/competitor-prices',
            competitivePricesAttributes: 'report/competitive-report-combos',
            comboChart: 'report/margin-by-state',
            comboChartAttributeItems: 'report/items-combo',
          },
        },
      },
      api: {
        resources: {
          competitorPricesMap: 'competitors/list-for-map',
          historicalPrice: 'competitors/price',
          historicalSales: 'competitors/sales',
          historicalPricesAndSales: 'report/prices-map',
        },
      },
      inconsistencyReports: {
        api: {
          resources: {
            getPriceInconsistency: 'item/get-price-inconsistency', //[WIP] DES-708 Ver q hacer con este EP
            categorys: 'categories/list',
          },
        },
      },
      suggestionReports: {
        api: {
          resources: {
            generateReport: 'report/prices-suggestions',
            generateReportAsync: 'report/prices-suggestions-async',
            getList: 'report/get-last-suggestions-reports',
          },
        },
      },
      costReports: {
        api: {
          resources: {
            generateReport: 'report/prices-costs',
            generateReportAsync: 'report/prices-costs-async',
            getList: 'report/get-last-costs-reports',
          },
        },
      },
      dailyMargin: {
        api: {
          resources: {
            getList: 'report/daily-margin-all-information',
            getExcelDailyMargin: 'report/daily-margin',
          },
        },
      },
      // TODO: VER CON BACK
      competitorPrices: {
        api: {
          resources: {
            competitorPricesExport: 'report/competitor-prices-for-export',
          },
        },
      },
    },
  },
  admin: {
    masters: {
      stores: {
        api: {
          resources: {
            feature: 'stores',
            vendors: 'stores/vendors',
            basicList: 'stores/basicAttributes',
            validateVendors: 'stores/validate-vendors',
            list: 'stores/list',
            getStoreList: 'stores/combo-list',
            listForExport: 'stores/list-for-export',
            shatedAttributes: 'stores/attributes/form',
            attributesByTagTarget: 'tags/attributes-by-tag-target',
            getTags2: 'tags/attributes-by-tag-target',
            getById: 'stores',
            existId: 'stores/exists',
            getCompetitorsInTradeArea: 'stores/get-tradearea',
            getAllCompetitors: 'competitors/list',
            getClusterings: 'clusterings/list',
            initializeVendors: 'stores/initialize-vendors',
          },
        },
      },
      storesv2: {
        api: {
          resources: {
            feature: 'stores',
            vendors: 'stores/vendors',
            basicList: 'stores/basicAttributes',
            validateVendors: 'stores/validate-vendors',
            list: 'stores/list',
            delete: 'stores/delete',
            undelete: 'stores/undelete',
            getStoreList: 'stores/combo-list',
            listForExport: 'stores/list-for-export',
            shatedAttributes: 'stores/attributes/form',
            attributesByTagTarget: 'tags/attributes-by-tag-target',
            getTags2: 'tags/attributes-by-tag-target',
            getById: 'stores',
            existId: 'stores/exists',
            getCompetitorsInTradeArea: 'stores/get-tradearea',
            getAllCompetitors: 'competitors/list',
            getClusterings: 'clusterings/list',
            initializeVendors: 'stores/initialize-vendors',
            clone: 'stores/clone',
            publishItem: 'stores/publish',
            getGenericStoreList: 'stores/list-generic',
            getGenericStoreListV2: 'stores/list-generic-specified-filter',
            getXslReport: 'point-of-sale/store-report',
            assortmentStatusFutureReport: 'assortment/assortment/assortment-status-future-report',
            storeScopeList: 'stores/get-storeids-from-scope',
            moveEquipmentToAnotherStore: 'equipmentAssigment/move-equipment-to-another-store',
            deleteEquipmentToStore: 'equipmentAssigment/delete-equipment-to-store',
          },
        },
      },
      suppliers: {
        api: {
          resources: {
            feature: 'vendors',
            list: 'vendors/list',
            productList: 'vendors/product-list',
            params: 'vendors/params',
            searchItemsParent: 'vendors/items',
            getOptionsItemsSelected: 'vendors/items-selected',
            searchStoresParent: 'vendors/stores',
            getOptionsStoreSelected: 'vendors/stores-selected',
          },
        },
      },
      supplierV2: {
        api: {
          resources: {
            feature: 'vendors',
            save: 'vendors',
            getVendor: 'vendors',
            list: 'vendors/list',
            productList: 'vendors/product-list',
            params: 'vendors/params',
            searchItemsParent: 'vendors/items',
            getOptionsItemsSelected: 'vendors/items-selected',
            searchStoresParent: 'vendors/stores',
            getOptionsStoreSelected: 'vendors/stores-selected',
            exist: 'vendors/exists',
            publishItem: 'vendors/publish',
          },
        },
      },
      equipments: {
        api: {
          resources: {
            getEquipmentsTypesList: 'equipments/list',
            getEquipmentTypeById: 'equipments/get-equipment', //Tipo de equipamiento
            saveEquipmentType: 'equipments/save-equipment',
            checkIfNameExists: 'equipments/checkIfNameExists', //:name
            deletEquipmentType: 'equipments', //?id=xx
            getAssigmentReport: 'equipmentAssigment/getAssigmentReport', //?id=xx
          },
        },
      },
      insights: {
        api: {
          resources: {
            getList: 'insights/list',
            getPanelList: 'insights/list-owner',
            subscription: 'insights/subscription',
            unsubscribe: 'insights/unsubscribe',
            getTypes: 'insights/insight-types',
          },
        },
      },
      tags: {
        api: {
          resources: {
            getList: 'tags/list',
            getAttributesList: 'tags/list/attributes',
            getFormById: 'tags',
            getAttributes: 'tags/attributes',
            save: 'tags',
            delete: 'tags',
            deleteValue: 'tags/deleteValue',
            deleteWarningTag: 'tags/delete-warning',
            getTagsTypeOptions: 'tags/types',
            getTagsDataTypeOptions: 'tags/datatypes',
          },
        },
      },
    },
    security: {
      users: {
        api: {
          resources: {
            list: 'identity/user',
            getUser: 'identity/user',
            newUser: 'identity/user/new',
            editUser: 'identity/user',
            getRoles: 'identity/role',
            resetPass: 'identity/user/reset-pass',
          },
        },
      },
      roles: {
        api: {
          resources: {
            list: 'identity/role',
            getRole: 'identity/role',
            newRole: 'identity/role/new',
            editRole: 'identity/role',
            getPermissions: 'identity/role/permission/list',
          },
        },
      },
    },
  },
  notification: {
    api: {
      resources: {
        getNotifications: 'notification-log/get-notifications',
        clear: 'notification-log/clear',
        clearAll: 'notification-log/clear-all',
      },
    },
  },
  mysteryshopper: {
    missions: {
      api: {
        resources: {
          getList: 'mystery-shopper/mission/list',
          getFormById: 'mystery-shopper/mission',
          getAttributes: 'mystery-shopper/mission/attributes',
          save: 'mystery-shopper/mission/save',
          getRuleExiste: 'mystery-shopper/mission/rule-exists',
          getAttributesList: 'mystery-shopper/mission/list/attributes',
          delete: 'mystery-shopper/mission/delete',
          getListForExport: 'mystery-shopper/mission/getListForExport',
          getDailyReport: 'mystery-shopper/mission/getDailyReport',
        },
      },
    },
    userAssignment: {
      api: {
        resources: {
          getList: 'mystery-shopper/userAssignment/list',
          getUsers: 'mystery-shopper/userAssignment/getUsers',
          unassignment: 'mystery-shopper/userAssignment/unassignRelation',
          clone: 'mystery-shopper/userAssignment/clone',
          save: 'mystery-shopper/userAssignment/save',
          getStoresByUser: 'mystery-shopper/userAssignment/getStoresByUser',
          getUserById: 'mystery-shopper/userAssignment',
          // deberian salir de prices porque consulta cosas de competidores
          getCompetitorsByUser: 'mystery-shopper/userAssignment/getCompetitorsByUser',
          getCompetitorsByStores: 'mystery-shopper/userAssignment/getCompetitorsByTradeAreaFromStores',
          getCompetitorsByCodes: 'mystery-shopper/userAssignment/getCompetitorsByCodes',
        },
      },
    },
  },
  spaces: {
    planograms: {
      api: {
        resources: {
          get: 'spaces/planograms/',
          save: 'spaces/planograms/save',
          delete: 'spaces/planograms/delete',
          deleteFromLayouts: 'spaces/planograms/deleteplanogramdetachfromlayout',
          getMenuProdList: 'spaces/items/get-menu-prod-list',
          getMenuPopList: 'spaces/popmaterial/get-menu-pop-list',
          getTimeLine: 'spaces/planograms/get-time-line',
          getAudit: 'spaces/planograms/get-audit',
          uploadImgAudit: 'spaces/planograms/upload-img-audit',
          getPlanogrmaComments: 'spaces/planograms/get-planogram-comments',
          postPlanogramComment: 'spaces/planograms/post-planogram-comment',
          planogramForExport: 'spaces/planograms/data-planogram-list',
          /**/
          list: 'spaces/planograms/list',
          toggleStatus: 'spaces/planograms/toggle-status',
          clonePlanogram: 'spaces/planograms/clone-planogram',
          clonePlanogramWithCode: 'spaces/planograms/clone-planogram-with-code',
          deletePlanogram: 'spaces/planograms/delete-planogram',
          listForExport: 'spaces/planograms/list-for-export',
          /**/
          savePopMaterial: 'spaces/popmaterial/save',
          getPopMaterial: 'spaces/popmaterial',
          getPopMaterialList: 'spaces/popmaterial/list',
          existCode: 'spaces/popmaterial/exists',
          exist: 'spaces/planograms/exists',
          deletePopMaterial: 'spaces/popmaterial/delete',
        },
      },
    },
    templates: {
      api: {
        resources: {
          getList: 'spaces/planograms/templates/list',
          clone: 'spaces/templates',
          delete: 'spaces/planograms/templates/delete',
          save: 'spaces/planograms/templates/save',
          existTemplate: 'spaces/planograms/templates/exists',
          getById: 'spaces/planograms/templates',
          getProductSelected: 'spaces/planograms/templates/get-items-by-attributes',
          generate: 'spaces/planograms/templates/generate',
          checkExist: 'spaces/planograms/templates/getplanogramsfortemplate',
          getStoresForGeneration: 'spaces/planograms/templates/get-stores-for-template',
          calculateDivisions: 'spaces/planograms/templates/calculate-divisions',
        },
      },
    },
    floorplans: {
      api: {
        resources: {
          getList: 'spaces/layouts/list',
          delete: 'spaces/layouts',
          //
          getPlanogramsList: 'spaces/layouts/planograms-list',
          getFloorPlanById: 'spaces/layouts',
          saveFloorPlan: 'spaces/layouts/save',
          publishFloorPlan: 'spaces/layouts/publish',
          analitycData: 'spaces/analytics/for-planograms',
        },
      },
    },
  },
  supply: {
    decisions: {
      orderControl: {
        api: {
          resources: {
            getList: 'orderControl/list',
            getFilters: 'orderControl/get-filters',
            getKpis: 'orderControl/get-kpis',
            sendDecision: 'orderControl/send-decision',
            getStatusOptions: 'orderControl/get-status-options',
            getOriginOptions: 'orderControl/get-origin-options',
            getDestinationOptions: 'orderControl/get-destination-options',
            getItemData: 'orderControl/itemdetail/get-item-detail-data',
            getItemDataGraph: 'orderControl/itemdetail/get-item-detail-data',
            getItemSteps: 'orderControl/itemdetail/get-item-detail-data',
          },
        },
      },
    },
    setup: {
      orderSchedule: {
        api: {
          resources: {
            getList: 'orderSchedule/list',
            getForm: 'orderSchedule/get-form',
            getOverlapList: 'orderSchedule/get-overlap-list',
            getStoreListBySupplier: 'orderSchedule/getStoreListBySupplier',
          },
        },
      },
    },
    radio: {
      api: {
        resources: {
          getRadioOptions: 'getRadioOptions',
        },
      },
    },
  },

  tagService: {
    api: {
      resources: {
        //dinamicTags: 'tags/attributesByTagTarget',
        dinamicTags: 'tags/attributes-by-tag-target',
        tagsByModele: 'tags/list-tag-by-target',
        gereicCategoryTree: 'categories/list-tree',
        getTagsForStores: 'tags/get-for-stores',
        getByDepartment: 'tags/get-by-department',
        getAllByDepartment: 'tags/get-used-in-department',
        getTagsAndCatsByItemScope: 'tags/get-attributes-from-scope',
      },
    },
  },

  genericImporterV2: {
    api: {
      resources: {
        importer: 'interfaces/input/{company}/{entity}/file',
        template: '{companyCode}/{environment}/importers/templates/{iotype}.xlsx',
        getInterfacesLogDetail: 'io', // api/io/{ioTaskId}
        getInterfacesLogList: 'interfaces/log', //POST
        retryFailedBatches: 'interfaces/InterfaceRetryControl/retry-failed-batches',
        getInterfacesLogIotypes: 'interfaces/log/iotypes',
        getIOType: 'interfaces/templates',
      },
    },
  },

  genericReportsV2: {
    api: {
      resources: {
        getReportsDefinitions: 'output/report/get-report-definitions',
        createReport: 'output/report/create-report',
        createReportAsync: 'output/report/create-report-async',
        getReportsList: 'output/report/get-reports-from-type',
      },
    },
  },

  supplierService: {
    api: {
      resources: {
        getSupplierCombo: 'supplier/get-combo',
      },
    },
  },
  distributionCenterService: {
    api: {
      resources: {
        getDistributionCenterCombo: 'distributionCenter/get-combo',
      },
    },
  },
};
