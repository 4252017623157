import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { MatBadgeModule } from '@angular/material/badge';
import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { VideosModalComponent } from 'app/helps/videos-modal/videos-modal.component';
import { HelpMainContentComponent } from 'app/helps/help-main-content/help-main-content.component';
import { HelpMainContentModule } from 'app/helps/help-main-content/help-main-content.module';

@NgModule({
  declarations: [ToolbarComponent, VideosModalComponent, HelpMainContentComponent],
  imports: [
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatBadgeModule,
    FuseSharedModule,
    FuseSearchBarModule,
    FuseShortcutsModule,
    MatTooltipModule,
    HelpMainContentModule,
  ],
  exports: [ToolbarComponent, MatTooltipModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ToolbarModule {}
