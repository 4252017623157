import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BANNED_PICK_COLOR_COMPONENT_V1, BANNED_PICK_COLOR_COMPONENT_V2 } from '@prisma/components/pick-color/pick-color.component';
import { _log } from '@shared/aux_helper_environment';
import { getDateFormat } from 'app/app.config';
import { _get$ } from 'core/services/ian-core-singleton.service';
import { checkDinamicColumn, PrismaTableTypePipes } from '../../prisma-table.model';

const dateFormat = getDateFormat();

@Component({
  selector: 'prisma-table-column-content',
  template: `
    <ng-container *ngIf="element[_column.column] !== null; else valueEmpty">
      <ng-container *ngIf="_column.type === prismaTableTypePipes.function && _column.typeFunc !== null; else customPipe">
        <ng-template
          [ngTemplateOutlet]="dinamicPipeTemplate"
          [ngTemplateOutletContext]="{ dinamicPipe: _column.typeFunc(element) }"
        ></ng-template>
      </ng-container>

      <ng-template #customPipe>
        <ng-template
          *ngIf="_column.type !== prismaTableTypePipes.function; else valueEmpty"
          [ngTemplateOutlet]="dinamicPipeTemplate"
          [ngTemplateOutletContext]="{ dinamicPipe: _column.type }"
        ></ng-template>
      </ng-template>

      <ng-template #dinamicPipeTemplate let-dinamicPipe="dinamicPipe">
        <span
          *ngIf="
            !dinamicPipe ||
            dinamicPipe === prismaTableTypePipes.empty ||
            dinamicPipe === prismaTableTypePipes.text ||
            dinamicPipe === prismaTableTypePipes.number
          "
        >
          {{ !!_column.customContent ? _column.customContent(element) : element[_column.column] }}
        </span>

        <span *ngIf="dinamicPipe === prismaTableTypePipes.int">
          {{ element[_column.column] | number : '1.0-0' }}
        </span>

        <span *ngIf="dinamicPipe === prismaTableTypePipes.priceInt"> {{ __$ }} {{ element[_column.column] | number : '1.0-2' }} </span>

        <span *ngIf="dinamicPipe === prismaTableTypePipes.price"> {{ __$ }} {{ element[_column.column] | number : '1.0-2' }} </span>

        <span *ngIf="dinamicPipe === prismaTableTypePipes.priceTwoDigits">
          {{ __$ }} {{ element[_column.column] | number : '1.2-2' }}
        </span>

        <span *ngIf="dinamicPipe === prismaTableTypePipes.maxTwoDigits">
          {{ element[_column.column] | number : '1.0-2' }}
        </span>

        <span *ngIf="dinamicPipe === prismaTableTypePipes.percent">
          {{ element[_column.column] | percent : '1.1-1' }}
        </span>

        <span *ngIf="dinamicPipe === prismaTableTypePipes.twoDigits">
          {{ element[_column.column] | number : '1.2-2' }}
        </span>

        <span *ngIf="dinamicPipe === prismaTableTypePipes.time">
          <ng-container *ngIf="element[_column.column] != null">
            {{ element[_column.column] | date : 'HH:mm' }}
          </ng-container>
          <ng-container *ngIf="element[_column.column] == null"> - </ng-container>
        </span>

        <span *ngIf="dinamicPipe === prismaTableTypePipes.date">
          <ng-container *ngIf="element[_column.column] != null">
            {{ element[_column.column] | date : 'yyyy/MM/dd' }}
          </ng-container>
          <ng-container *ngIf="element[_column.column] == null"> - </ng-container>
        </span>
        <span *ngIf="dinamicPipe === prismaTableTypePipes.dateV2">
          <ng-container *ngIf="element[_column.column] != null">
            {{ element[_column.column] | dateLenguage }}
          </ng-container>
          <ng-container *ngIf="element[_column.column] == null"> - </ng-container>
        </span>
        <span *ngIf="dinamicPipe === prismaTableTypePipes.dateAlt">
          <ng-container *ngIf="element[_column.column] != null">
            {{ element[_column.column] | dateLenguage }}
          </ng-container>
          <ng-container *ngIf="element[_column.column] == null"> - </ng-container>
        </span>

        <span *ngIf="dinamicPipe === prismaTableTypePipes.dateTime">
          <ng-container *ngIf="element[_column.column] != null">
            {{ element[_column.column] | dateTimeLenguage }}
          </ng-container>
          <ng-container *ngIf="element[_column.column] == null"> - </ng-container>
        </span>

        <span
          *ngIf="dinamicPipe === prismaTableTypePipes.customContent"
          [ngClass]="!!_column?.customClassContent ? _column?.customClassContent(element) : !!_column.customAction ? 'clickeable' : null"
          (click)="_column.customAction(element)"
        >
          {{ _column?.customContent(element, this.translate) }}
          <mat-icon *ngIf="!!_column?.icon" style="vertical-align: text-top; font-size: 18px;"> {{ _column.icon(element) }} </mat-icon>
        </span>

        <span
          *ngIf="dinamicPipe === prismaTableTypePipes.spinnerWithCustomContent"
          [ngClass]="_column?.customClassContent ? _column?.customClassContent(element) || null : null"
        >
          <ng-container *ngIf="!_column?.spinner(element) || false">{{
            _column?.customContent(element, this.translate) || null
          }}</ng-container>
          <custom-loader
            *ngIf="_column?.spinner(element) || false"
            [text]="_column?.customContent(element, this.translate) || null"
            [size]="0.7"
            [top]="'10px'"
          ></custom-loader>
        </span>
        <!--
            (mouseenter)="showAction(element)"
            (mouseleave)="hiddenAction()"
         -->
        <div
          *ngIf="dinamicPipe === prismaTableTypePipes.actions"
          [ngClass]="
            _column?.customClassContent
              ? _column?.customClassContent(element) || 'actions-btns UXv2_actions-btns'
              : 'actions-btns UXv2_actions-btns'
          "
        >
          <ng-container *ngFor="let action of _column.customContent">
            <div style="display:inline-block" [matTooltip]="action?.matTooltip ? action.matTooltip(element) : null">
              {{ action?.column ? element[action.column] : null }}

              <button
                *ngIf="
                  (action.show &&
                    ((!!action?.hiddenIcon && !action.hiddenIcon(element)) || !action?.hiddenIcon) &&
                    rowWithHover === element) ||
                  action?.withoutHover === true
                "
                mat-icon-button
                (click)="action.onClick(element); $event.preventDefault(); $event.stopPropagation()"
                [disabled]="action?.disabled ? action.disabled(element) : false"
                [attr.data-test-id]="action?.dataTestId ? action.dataTestId(element) : action.icon"
              >
                <mat-icon
                  class="small-icon icon-actions-btns"
                  [class]="action?.iconClass || null"
                  [ngStyle]="{ color: action?.disabled ? (action.disabled(element) ? 'lightgrey' : null) : null }"
                  >{{ action.icon }}</mat-icon
                >
              </button>
            </div>
          </ng-container>
        </div>

        <div *ngIf="dinamicPipe === prismaTableTypePipes.icon" style="min-height:24px; min-width:24px;">
          <mat-icon
            class="clickeable"
            *ngIf="!!_column.customContent(element)"
            [ngClass]="_column?.customClassContent ? _column.customClassContent(element) : null"
            (click)="_column.customAction(element)"
          >
            {{ _column.customContent(element) }}
          </mat-icon>
          <span *ngIf="!_column.customContent(element)">-</span>
        </div>

        <div *ngIf="dinamicPipe === prismaTableTypePipes.color" style="min-height:24px; min-width:24px;">
          <div
            class="tag-color"
            *ngIf="element.color !== white && element.color !== whitev2 && element.color"
            [style.background-color]="element.color"
          ></div>
          <span *ngIf="element.color === white || element.color === whitev2 || !element.color">-</span>
        </div>
      </ng-template>
    </ng-container>

    <ng-template #valueEmpty>
      <span *ngIf="element[_column.column] === null && _column.markOnNull"> - </span>
    </ng-template>
  `,
  styles: [
    `
      .actions-btns {
        background-color: transparent;
        position: absolute;
        right: 24px;
        margin-top: -22px;
        margin-right: 4px;
        min-width: 122px;
        min-height: 44px;
        text-align: center;
      }

      .tag-color {
        width: 20px;
        height: 20px;
        background-color: red;
        border-radius: 50%;
      }

      .actions-btns .icon-actions-btns {
        color: rgba(0, 0, 0, 0.87);
        font-size: 22px;
        z-index: 1000;
      }

      .actions-btns:hover {
        background-color: #f5f5f5;
      }

      ::ng-deep prisma-table .fixedTable tbody {
        position: relative;
      }

      ::ng-deep prisma-table .fixedTable .actions-btns {
        right: 0px;
      }
    `,
  ],
})
export class PrismaTableColumnContentComponent implements OnInit {
  @Input() element: any;
  @Input('configColumn') _column: checkDinamicColumn;
  @Input() rowWithHover;
  prismaTableTypePipes = PrismaTableTypePipes;
  __$ = _get$();
  constructor(public translate: TranslateService) {}

  white = BANNED_PICK_COLOR_COMPONENT_V1;
  whitev2 = BANNED_PICK_COLOR_COMPONENT_V2;

  ngOnInit(): void {}

  /*   showAction(row) {
    this.rowWithHover = row;
  }

  hiddenAction() {
    this.rowWithHover = null;
  } */
}
