import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { _log, _warn } from '@shared/aux_helper_environment';
import { BehaviorSubject, Observable } from 'rxjs';

const _global = window as any;

//Para testear notificación
/*

curl -X POST https://fcm.googleapis.com/fcm/send -H 'Authorization:key=AAAAbO1V2WU:APA91bHtSd5HHFE4zdpY1DnnKI4tM93KtpDhC1e_ifbbveWLNey06uEedAVt6OIRWlV9l970Qjo7RGZWWFCEd9DBIghR6pqDlmObTSKVo3YXaVH6_IcPQkyMQX7er2ODFHFKNRLvu3rd' -H 'Content-Type: application/json' -d '{  "notification": { "title": "Título Push\ncon 2 líneas.", "body": "Body Push\ncon 2 líneas."  },  "to" : "fd3AcoBO8gB7cr-NCVsrAA:APA91bEl8IbMj3TeTyAI6bMFkaNdADRipOTuaiJGrRA-Gs_3bg54ajmZdisQsgSISJgHOm_tJmJkdfMG3VUMxbE-gCue-PNeB8E_VG5TSsL7O3yZSSYgE7LBbtCCuu-23LUI2HQRqyi5" }'

curl -X POST https://fcm.googleapis.com/fcm/send -H 'Authorization:key=AAAAbO1V2WU:APA91bHtSd5HHFE4zdpY1DnnKI4tM93KtpDhC1e_ifbbveWLNey06uEedAVt6OIRWlV9l970Qjo7RGZWWFCEd9DBIghR6pqDlmObTSKVo3YXaVH6_IcPQkyMQX7er2ODFHFKNRLvu3rd' -H 'Content-Type: application/json' -d '{  "notification": { "title": "Título Push\ncon 2 líneas.", "body": "{\"message\":\"Descargue el reporte de la tienda ST100\",\"urlToDownload\":\"https://iantechdevstorage.blob.core.windows.net/megademolab/Development/Pricing/reports/PosItemsDataWithAssortment/2023-09-21_15-05-11-PosItemsDataReport.xlsx\"}"  },  "to" : "dcOXjsPo3kCMs843NwnERq:APA91bHDbLibulj0xCnM6HqDyyrWbLxkhLxvTIxG5eLvZX_LUphj9ofRFK3e3M2nR4hY3wF6_TkkAlvcQzQ5JCAs6bRef9SQFDdT1Y1tbSsTHtcugVcQQjgEK5C5nBU-owdTQw-bWgWB" }'

curl -X POST https://fcm.googleapis.com/fcm/send -H 'Authorization:key=AAAAbO1V2WU:APA91bHtSd5HHFE4zdpY1DnnKI4tM93KtpDhC1e_ifbbveWLNey06uEedAVt6OIRWlV9l970Qjo7RGZWWFCEd9DBIghR6pqDlmObTSKVo3YXaVH6_IcPQkyMQX7er2ODFHFKNRLvu3rd' -H 'Content-Type: application/json' -d '{  "notification": { "title": "Título Push\ncon 2 líneas.", "body": "{\"message\":\"Descargue el reporte de la tienda ST100\",\"urlToDownload\":\"#/home/dashboard"}"  },  "to" : "d2B35ATg-xSN8P-N3sfGlb:APA91bFIsW-MunMBUUebU9TYFQHJpbG-9k6JB2uuk4BEctQGbdPelsrIJg64yYjIU1oc2xerBEn85WBV7ZInIHiW6Xow4Bo8cFE99Nm-JGJRNRr82_c_iUyzpZc_wIvpvEDaAi9u4F7H" }'

*/

//Basado en: https://medium.com/@mayurkadampro/angular-8-firebase-cloud-messaging-push-notifications-cc80d9b36f82
@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  currentToken: string = null;

  hasFirebaseRequestPermissionToken = 'NONE';

  firebaseRequestPermissionError = null;

  hasNotificationPermission = this.checkHasNotificationPermission();

  constructor(private angularFireAuth: AngularFireAuth, private angularFireMessaging: AngularFireMessaging) {
    try {
      (this.angularFireMessaging as any).onMessage(payload => {
        _log('🔔 [MessagingService Push Notification]', payload);
        this.currentMessage.next(payload);
      });
    } catch (e) {
      _warn('🔔💥💥💥 [MessagingService Push Notification]', e);
    }
  }

  checkHasNotificationPermission() {
    return window?.Notification?.permission === 'granted';
  }

  requestPermission() {
    this.hasFirebaseRequestPermissionToken = 'PENDING';

    this.angularFireMessaging.requestToken.subscribe(
      token => {
        _log('\n', ['firebase requestPermission ok, token:'], token, '\n\n');
        this.currentToken = token;

        this.hasFirebaseRequestPermissionToken = token?.length ? 'OK' : 'FAIL';

        this.firebaseRequestPermissionError = token?.length ? null : 'token is empty';

        if (_global) {
          _global._info = _global._info ? _global._info : {};
          _global._info._FBtoken = token;
        }
      },
      err => {
        console.error(
          '\n',
          '[firebase requestPermission fail]',
          { error: err, hasNotificationPermission: this.hasNotificationPermission },
          '\n'
        );

        if (_global) {
          _global._info = _global._info ? _global._info : {};
          _global._info._FBtokenError = err;
        }

        this.hasFirebaseRequestPermissionToken = 'FAIL';

        this.firebaseRequestPermissionError = err;
      }
    );
  }

  getToken(): string {
    return this.currentToken;
  }

  getHasFirebaseRequestPermissionToken(): string {
    return this.hasFirebaseRequestPermissionToken;
  }

  getHasNotificationPermission(): boolean {
    return this.hasNotificationPermission;
  }

  getAlertEnabledNotifications(): boolean {
    return this.hasFirebaseRequestPermissionToken === 'FAIL' && this.hasNotificationPermission === false;
  }

  getFirebaseRequestPermissionError(): any {
    return this.firebaseRequestPermissionError;
  }
}
